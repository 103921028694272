import { action, makeObservable, observable, runInAction } from "mobx";

import { bookAPI } from "../shared/api";
import { DatesResponse, TimesResponse } from "../shared/api/book";
import { Maybe } from "../types/utils/monads";
import { BaseStore } from "./lib/baseStore.type";

import moment, { Moment } from "moment";

class WorkTimes implements BaseStore {
  @observable selectedDay: Moment = moment();
  @observable bookingDays: DatesResponse["booking_days"] = {
    11: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
  }; // need change
  @observable isLoading = false;
  @observable timesLoading = false;
  @observable workingTimes: Maybe<TimesResponse[]> = null;
  @observable selectedTime: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setSelectedDay(date: Moment) {
    this.selectedDay = date;
  }

  @action
  async fetchWorkTimes(companyId: number, selectedServices?: string) {
    const [month, day] = moment().format("M:D").split(":");
    if ((this.bookingDays[Number(month)] ?? []).includes(Number(day))) {
      try {
        this.setTimesLoading(true);
        const date = this.selectedDay.clone();
        const { data } = await bookAPI.getTimesByStaffId({
          companyId,
          selectedServices: selectedServices ? [selectedServices] : undefined,
          date: date.toISOString(),
        });
        runInAction(() => (this.workingTimes = data.data));
      } catch (error) {
      } finally {
        this.setTimesLoading(false);
      }
    } else {
      this.workingTimes = [];
    }
  }

  @action
  setTimesLoading(value: boolean) {
    this.timesLoading = value;
  }

  @action
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @action
  setSelectedTime(time: string) {
    this.selectedTime = time;
  }

  @action
  resetStore() {
    this.selectedDay = moment();
    // this.bookingDays = {};
    this.selectedTime = null;
    this.workingTimes = null;
    this.workingTimes = null;
  }
}

export const workTimes = new WorkTimes();
